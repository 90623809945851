import { defineStore } from "pinia";

export const useMerchantStore = defineStore(
  "merchant",
  () => {
    const profile = ref({});

    function $reset() {
      profile.value = {};
    }

    function set(params: any) {
      const myStore = useMerchantStore();
      const states: any = toRefs(myStore.$state);
      const keys = Object.keys(params);
      keys.forEach((key) => {
        states[key].value = params[key];
      });
    }

    const register = (payload: any) => {
      const config = useRuntimeConfig().public;
      return $fetch<any>(`${config.API_URL}/merchant/v1/merchant-user/reg`, {
        method: "POST",
        body: payload,
      });
    };

    return { profile, register, set, $reset };
  },
  {
    persist: true,
  },
);
